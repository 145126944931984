import React from "react"

const Loader = () => {
  return (
    <>
      <img className="loader" src="../assets/loader_dots.gif" alt="" />
    </>
  )
}

export default Loader
