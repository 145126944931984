import React from "react"

const Ask = () => {
  return (
    <div className="mainAsk">
      <img
        className="maurice_img mobile"
        src="./assets/lion_mobile_v2.png"
        alt=""
      />
      <img
        className="maurice_img desktop"
        src="./assets/lion_desktop_v2.png"
        alt=""
      />

      <div className="maurice">
        <img src="../assets/avatar_Maurice.png" alt="Avatar Leon" />
        <div className="maurice_text">
          <h2>Leon</h2>
          <p>
            Chief Metavers Officer
            <span>@Publicis Groupe</span>
          </p>
          <p className="today">Today</p>
        </div>
      </div>

      <div className="linkedin">
        <img src="./assets/linkedin_blue.png" alt="Logo Linkedin" />
        <p>
          <a href="https://www.linkedin.com/in/leon-chief-metaverse-officer-publicis/" target="_blank" rel="noopener noreferrer">
            Follow me<span className="desktop">&nbsp; on Linkedin</span>
          </a>
        </p>
      </div>
    </div>
  )
}

export default Ask
