import React, { useState, useEffect, useRef } from "react"
import Button from "./Button"
import Linkify from "react-linkify"
import Loader from "./Loader"

const Question = () => {
  // const [tooglMenu, setTooglMenu] = useState(false)
  const [getQues, setQues] = useState("")
  // const [getAns, setAns] = useState("")
  const [getID, setID] = useState(0)
  const [items, setItems] = useState([])

  const inputElement = useRef()

  let IDList = JSON.parse(localStorage.getItem("randomIDs") || "[]")

  function checkID(id) {
    if (!IDList.includes(id)) {
      setID(id)
      IDList.push(id)
      localStorage.setItem("randomIDs", JSON.stringify(IDList))
    } else {
      generateID()
    }
  }

  function generateID() {
    let id = Math.floor(Math.random() * 100000)
    checkID(id)
  }

  useEffect(() => {
    checkID(Math.floor(Math.random() * 100000))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let _data = {
    id: { getID },
    text: { getQues },
  }

  const handleToogleMenu = () => {
    let currQues = inputElement.current.value
    setItems([
      ...items,
      {
        value: currQues,
        ans: "",
      },
    ])
    inputElement.current.value = ""

    let _data2 = {
      id: _data.id.getID,
      text: currQues,
    }

    // console.log(items)

    fetch("https://gpt3.99rdd.io/askmaurice", {
      method: "POST",
      body: JSON.stringify(_data2),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          setItems([
            ...items,
            {
              value: currQues,
              ans: result.choices[0].text,
            },
          ])
        },

        (error) => {
          console.log(error)
        }
      )
  }

  useEffect(() => {}, [])

  const handleKeyPress = (e) => {
    // e.preventDefault()
    if (e.key === "Enter") {
      handleToogleMenu()
      inputElement.current.blur()
    }
  }

  return (
    <>
      <div className="chatbox-wrap">
        <div className="chat-header">
          <div className="avatar">
            <img
              src="../assets/avatar_maurice_petit.png"
              alt="Avatar Leon"
            />
          </div>
          <div className="text">
            <h3>Leon</h3>
            <p>Disponible</p>
          </div>
        </div>
        <div className="chat-main-wrapper">
          <div className="ques-wrapper">
            <div className="avatar">
              <img src="../assets/avatar_Maurice.png" alt="Avatar Leon" />
            </div>
            <div className="chat-content">
              <h4>Leon</h4>
              <p>
                I’m pretty sure you’ve got a meta question, feel free to ask:
              </p>
            </div>
          </div>
          <div className="chat ans-wrapper">
            {items.map(
              (item, index) =>
                item && (
                  <div key={index}>
                    <div className="chat-ques">
                      <div className="avatar">
                        <img src="../assets/avatar_you.png" alt="Avatar You" />
                      </div>
                      <div className="chat-content">
                        <h4>You</h4>
                        <p>{item.value}</p>
                      </div>
                    </div>
                    <div className="chat-ques">
                      <div className="avatar">
                        <img
                          src="../assets/avatar_Maurice.png"
                          alt="Avatar Leon"
                        />
                      </div>
                      <div className="chat-content">
                        <h4>Leon</h4>
                        <p>
                          {item.ans ? (
                            <Linkify>{item.ans}</Linkify>
                          ) : (
                            <Loader />
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>

        <form>
          <textarea
            ref={inputElement}
            rows="2"
            type="text"
            placeholder="Your question here..."
            onChange={(e) => setQues(e.target.value)}
            onKeyPress={handleKeyPress}
            // maxLength={maxCaracters}
          />
          <Button value="send" handleClick={handleToogleMenu} />
        </form>
      </div>
    </>
  )
}

export default Question
