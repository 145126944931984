import React from "react"
import Ask from "./components/Ask"
import Footer from "./components/Footer"
import Question from "./components/Question"

const App = () => {
  return (
    <div className="container">
      <div className="main">
        <Ask />
        <div className="mainContainer">
          <Question />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
