import React from "react"

const Footer = () => {
  return (
    <footer>
      <div className="footerLogo">
        {/* <img src="./assets/footer/PictoPublicis.png" alt="Logo Publicis" /> */}
        <img src="./assets/footer/PictoPublicis.png" alt="Logo Publicis" />
        <p>
          <a
            href="https://www.publicisgroupe.com/fr/mentions-legales"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mention Légales
          </a>
        </p>
        <div className="footerLogo_text">
          <p>
            Publicis Groupe traite vos données personnelles en qualité de
            responsable de traitement afin de vous envoyer des communications
            liées à l'activité de Publicis France.
          </p>
          <p><span>©</span>publicis all rights reserved</p>
        </div>
      </div>
      <div className="footerLinks">
        <p>Suivez-nous</p>
        <div className="footerLinks_Socials">
          <a
            href="https://twitter.com/publicis_france"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="./assets/reso-social/twitter.svg"
              alt="Twitter"
              height="40px"
              width="40px"
            />
          </a>
          <a
            href="https://www.instagram.com/publicisfrance/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="./assets/reso-social/instagram.svg"
              alt="Instagram"
              height="40px"
              width="40px"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/publicis-france/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="./assets/reso-social/linkedin.svg"
              alt="Linkedin"
              height="40px"
              width="40px"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCPT3aOfa6nVyWsp5pTWcSFA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="./assets/reso-social/youtube.svg"
              alt="YouTube"
              height="40px"
              width="40px"
            />
          </a>
          <a
            href="mailto:communication_france@publicis.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="./assets/reso-social/envelope.svg"
              alt="Email"
              height="40px"
              width="40px"
            />
          </a>
        </div>
      </div>
      <div className="footerMentions">
        <p>
          <a
            href="https://www.publicisgroupe.com/fr/mentions-legales"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mention Légales
          </a>
        </p>
        <p>
          Publicis Groupe traite vos données personnelles en qualité de
          responsable de traitement afin de vous envoyer des communications
          liées à l'activité de Publicis France.
        </p>
        <p>
          <span>©</span>publicis all rights reserved
        </p>
      </div>
    </footer>
  )
}

export default Footer
